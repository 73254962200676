<template>
    <div class="wrapper -login">
        <div class="contenedor-formulario -recuperar-pass column-center">
            <div class="contenedor-logo">
                <img src="/img/logo-uc3m-alumni-dark.png" alt="Logo u3cm" />
            </div>

            <ValidationObserver ref="form" v-slot="{ failed, valid }">
                <form @submit.prevent="validate" class="formulario column-center" :valid="valid" :failed="failed">
                    <div class="contenedor-input">
                        <ValidationProvider
                            :rules="{ required: true, regex: /^(?=.*\d)(?=.*[\u0021\u003F\u0023\u0040\u0024\u0025\u0026\u0028\u0029\u002B\u002D\u002F\u002A\u003D\u005B\u005D\u007B\u007D\u005F\u002E\u003A\u003B])(?=.*[A-Z])(?=.*[a-z])\S{8,30}$/ }"
                            v-slot="{ errors, ariaInput, ariaMsg, classes }"
                            vid="password"
                        >
                            <label for="email">
                                Contraseña*
                                <popup-info :data="'contrasena'"></popup-info>
                            </label>
                            <input type="password" :class="classes" v-model="data.password" name="password" />
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="contenedor-input">
                        <ValidationProvider
                            :rules="{ required: true, confirmed: 'password', regex: /^(?=.*\d)(?=.*[\u0021\u003F\u0023\u0040\u0024\u0025\u0026\u0028\u0029\u002B\u002D\u002F\u002A\u003D\u005B\u005D\u007B\u007D\u005F\u002E\u003A\u003B])(?=.*[A-Z])(?=.*[a-z])\S{8,30}$/ }"
                            v-slot="{ errors, ariaInput, ariaMsg, classes }"
                        >
                            <label for="email">Repetir contraseña*</label>
                            <input type="password" :class="classes" v-model="data.password_confirmation" name="password" @keyup.enter="validate" />
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-check -dark">
                        <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input :class="classes" id="rgpd" name="rgpd" v-model="data.rgpd" type="checkbox" placeholder="rgpd" v-bind="ariaInput" />
                            <label for="rgpd">
                                Declaro haber entendido la información facilitada y consiento el
                                tratamiento que se efectuará de mis datos de carácter personal con la finalidad de
                                inscribirme en el programa.
                            </label>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-check -dark" v-if="data.type == 'profesor'">
                        <input id="visible" name="visible" v-model="data.visible_by_others" type="checkbox" placeholder="visible" />

                        <label for="visible" class="width-auto">Acepto formar parte de la Red de Mentores de este programa.</label>
                        <popup-info class="white" :data="'visible-otros-registro'"></popup-info>
                    </div>

                    <div class="texto-legal">
                        <h2>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS.</h2>
                        <p>RESPONSABLE DEL TRATAMIENTO. Universidad Carlos III de Madrid. Contacto DPO: dpd@uc3m.es</p>
                        <p>IDENTIFICACIÓN DEL TRATAMIENTO, MENTORING ALUMNI UC3M</p>
                        <p>
                            FINALIDADES. Gestionar su inscripción y participación como mentor/mente (cada uno en su
                            caso) en el programa Mentoring Alumni UC3M, así como participar en todas las actividades que
                            puedan desarrollarse en el seno de la actividad del programa.
                        </p>
                        <p>
                            EJERCICIO DE DERECHOS. Podrá ejercer sus derechos de acceso, rectificación, supresión,
                            limitación, portabilidad y oposición a su tratamiento, mediante el envío de un correo a la
                            siguiente dirección: dpd@uc3m.es
                        </p>
                        <p>
                            INFORMACIÓN ADICIONAL: Puede consultar la información adicional y detallada sobre nuestra
                            Política de Privacidad en
                            <a href="https://www.uc3m.es/protecciondedatos" target="_blank">https://www.uc3m.es/protecciondedatos</a>
                        </p>
                    </div>

                    <div class="contenedor-boton">
                        <a @click="validate" class="btn -skull-blue" :loading="loading">Aceptar RGPD</a>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

import router from '@/router';

import { mapActions } from 'vuex';

export default {
    name: 'reset-password',
    mounted() {
        this.data.type = (this.$route.params.id == 'mentee') ? 'mentee' : 'profesor';
        this.data.token = this.$route.query.token;
    },
    data: () => ({
        data: {
            password: '',
            password_confirmation: '',
            type: '',
            token: '',
            visible_by_others: null,
            rgpd: null
        },
        loading: false
    }),
    methods: {
        ...mapActions(['rgpdGet']),
        validate() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.loading = true;
                    this.rgpdGet(this.data).then(r => {
                        if (r) {
                            setTimeout(() => {
                                this.loading = false;
                                sessionStorage.setItem('rgpd_form_values', JSON.stringify(this.data));
                                router.push({ name: 'completar-cuenta' });
                            }, 1000);
                        }
                    });
                }
            });
        }
    }
}
</script>


<style lang="scss" scoped>
.texto-legal p {
    color: #1b1f3d;
}
</style>